<template>
  <SubNav class="subnav-position" :selected="'team'" :buildingId="buildingId" />
  <main>
    <form @submit.prevent="handleSubmit" class="shadow edit-form">
      <div class="float-end">
        <router-link
          :to="{ name: 'Spaces', params: { buildingId: buildingId } }"
          ><h4>&times;</h4></router-link
        >
      </div>
      <h6>Create a New Office Suite</h6>
      <div class="form-outline mb-4">
        <label>Name</label>
        <input type="text" required v-model="name" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Suite Number</label>
        <input
          type="text"
          required
          v-model="suiteNumber"
          class="form-control"
        />
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          id="allowUSInvestors"
          v-model="available"
        />
        <label class="form-check-label" for="flexCheckChecked">
          Show as Available?
        </label>
      </div>
      <div class="error">{{ errorForm }}</div>
      <div v-if="!isPending" class="row text-center">
        <div v-if="!errorForm" class="col">
          <button class="btn btn-primary">Create</button>
        </div>
        <div v-else class="col">
          <button class="btn btn-secondary" disabled>Create</button>
        </div>
        <div class="col">
          <router-link
            :to="{ name: 'Spaces', params: { buildingId: buildingId } }"
            class="btn btn-secondary"
          >
            Cancel
          </router-link>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Creating...</span>
          </div>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import { ref } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser.js";
import { timestamp } from "@/firebase/config";
import { useRouter } from "vue-router";
import SubNav from "@/components/navigation/SubNav.vue";
export default {
  props: ["buildingId"],
  components: { SubNav },
  setup(props) {
    const errorForm = ref(null);
    const isPending = ref(false);
    const name = ref(null);
    const suiteNumber = ref(null);
    const available = ref(false);
    const router = useRouter();

    const { user } = getUser();
    const { error: errorAdd, addDoc } = useCollection("spaces");

    const handleSubmit = async () => {
      try {
        errorForm.value = null;
        isPending.value = true;
        const ref = await addDoc({
          name: name.value,
          suiteNumber: suiteNumber.value,
          available: available.value,
          managerId: user.value.uid,
          buildingId: props.buildingId,
          order: 0,
          createdAt: timestamp()
        });
        isPending.value = false;
        if (errorAdd.value) {
          errorForm.value = errorAdd.value;
        } else {
          router.push({
            name: "EditSpace",
            params: { buildingId: props.buildingId, spaceId: ref.id }
          });
        }
      } catch (err) {
        errorForm.value = err.message;
      }
    };
    return { isPending, name, suiteNumber, available, handleSubmit, errorForm };
  }
};
</script>

<style>
</style>